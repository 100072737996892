//@ts-nocheck
import axios from 'axios';
import { Token } from '../../../../Core/Interfaces/interfaces';
import Cookies from 'cookies-js';
import config from '../../../../Config';

class BonfireAPI {

    static createAxiosClient(
        baseURL: string,
        contentTypes?: object,
    ) {
        const COOKIE = Cookies.get(config.cookie.name);
        
        var token: Token | null = null;

        try {
            token = JSON.parse(COOKIE);
        } catch (error) {
            console.log("error?", error)
        }

        const defaultHeaders: any = {
            Accept: 'application/json',
        };

        if (token) {
            defaultHeaders.Authorization = `Bearer ${token.access_token}`;
        }

        const client = axios.create({
            baseURL,
            headers: contentTypes ? { ...defaultHeaders, ...contentTypes } : defaultHeaders,
        });
        
        // AccessToken needs to be dybnamically assigned because it can change meanwhile.
        // Like the case for Zoom Dashboard showing welcome screen and once consent screen is accepted and user account created,
        // this will try to refresh the user with the new access token.
        client.interceptors.request.use(
            function (axiosConfig) {
                var token: Token | null = null;
                try {
                    token = JSON.parse(Cookies.get(config.cookie.name));
                } catch (error) {
                    console.log("can't parse access token cookie", error);
                }
                if (token) {
                    axiosConfig.headers.Authorization = `Bearer ${token.access_token}`;
                }
                return axiosConfig;
            }
        );
        
        return client;
    }

    static async renewToken() {
        return false;
    }

    static async checkAndRenewToken() {
        return false;
    }

    static async logout() {
        
        Cookies.defaults = {
            path: '/',
            domain: config.cookie.domain,
        };

        Cookies.expire(config.cookie.name);

        const accessCookie = `${Cookies.get(config.access.api.cookieName)}`;

        if (accessCookie) {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessCookie,
                },
                credentials: 'include',
            };
        
            await fetch(`${config.access.api.url}/user/logout`, options);
        }

        return false;
    }

}

export default BonfireAPI;

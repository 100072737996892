// @ts-nocheck
import {
  Box,
  Center,
  Text,
  Button,
  Input,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
} from "@chakra-ui/react";
import T from "../../../Core/Translations";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BonfireAPI from "../../../Core/Data/Http";
import { AUTH_SESSION } from "../../../Models/SessionsAuth/urls";
import SessionsAuthModel from "../../../Models/SessionsAuth";
import JoinSessionViewModel from "../Core/viewModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { CLR_PANEL_BG } from "../../../../Lib/Theme/Light/colors";

const JoinSession = () => {
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("type");
    const copyFrom = searchParams.get("copyFrom");

    if (type) {
      if (copyFrom) createSession(); // if copyFrom is passed in, then we display the create form
      else processExternalJoin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processExternalJoin = async () => {
    const type = searchParams.get("type");

    try {
      setIsPageLoading(true);

      const data = await JoinSessionViewModel.processJoinProvider(type, {
        meetingId: searchParams.get("meetingId"),
      });
      if (data.raw) {
        sessionJoined(data, type);
        setIsPageLoading(false);
      } else {
        if (data.isOwner) {
          createSession(true); // true: indicating that the AI Pro is loaded in an IFRAME from BoostEvents:/presentations/linked-to page
          setIsPageLoading(false);
        } else {
          // Retry getting the session and show the text
          setTimeout(() => {
            processExternalJoin();
          }, 3000);
        }
      }
    } catch (error) {
      setIsPageLoading(false);
    }
  };

  const createSession = async (isLinkedToEvent) => {
    var languages = searchParams.get("languages");

    if (!languages) {
      languages = ["en"];
    } else {
      languages = languages.split(",");
    }

    navigate("/create-session", {
      state: {
        options: {
          type: searchParams.get("type"),
          meetingId: searchParams.get("meetingId"),
          meetingKey: searchParams.get("meetingKey"),
          clientId: searchParams.get("clientId"),
          canApprove: searchParams.get("canApprove"),
          noApprovalNeeded: searchParams.get("noApprovalNeeded"),
          namespace: searchParams.get("namespace"),
          joinUrl: searchParams.get("joinUrl"),
          password: searchParams.get("password"),
          websocket: searchParams.get("websocket"),
          languages,
          initialUrl: window.location.href,
        },
        isLinkedToEvent,
        quickCreate: searchParams.has("quickCreate"),
        copyFrom: searchParams.get("copyFrom"),
      },
    });
  };

  const join = async () => {
    setError("");
    setIsLoading(true);

    try {
      const data = await BonfireAPI.request(AUTH_SESSION, SessionsAuthModel, {
        sessionId,
      });
      sessionJoined(data);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  };

  const sessionJoined = (data, type = null) => {
    navigate(`/session-joined/${data.raw.session_id}`, {
      state: {
        session: data,
        websocket: searchParams.get("websocket"),
        initialUrl: window.location.href,
        type,
      },
    });
  };

  if (isPageLoading) {
    return <Spinner />;
  }

  return (
    <Center
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mt={[0, 0, 20]}
    >
      <Flex
        w={["full", "full", "800px"]}
        bgColor={CLR_PANEL_BG}
        flexDirection="column"
        h={["calc(100svh)", "calc(100svh)", "full"]}
        padding={[8, 8, 10]}
        borderRadius={8}
        borderWidth={[0, 0, 1]}
      >
        <Box>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          >
            <FontAwesomeIcon
              icon={faLongArrowLeft}
              size={"xl"}
              color={"#4190C3"}
            />
          </a>

          <Text mt={5} variant="beTextHeading">
            {T.get("Join session")}
          </Text>
          <Text variant="beTextDescription">
            {T.get("Please enter the session id of the event you wish to join")}
          </Text>
        </Box>
        <Box>
          <Box>
            {error && (
              <Alert variant="subtle" status="error" mb={5}>
                <AlertIcon />
                <AlertTitle mr={1} fontWeight="bold">
                  {T.get("Error")}
                </AlertTitle>
                <AlertDescription>{T.get(error)}</AlertDescription>
              </Alert>
            )}
            <Input
              value={sessionId}
              placeholder={T.get("Please enter session ID")}
              onChange={(e) => setSessionId(e.target.value)}
              shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
              _placeholder={{ fontWeight: "normal" }}
              fontWeight="400"
              bgColor="white"
              mt="4"
            />
          </Box>
        </Box>
        {isLoading ? (
          <Spinner mt={5} />
        ) : (
          <Button
            marginTop="auto"
            variant="beSecondary"
            w="full"
            mt={5}
            onClick={() => join()}
          >
            {T.get("Join")}
          </Button>
        )}
      </Flex>
    </Center>
  );
};

export default JoinSession;

import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import {
    Box,
    Flex,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Heading,
    Select,
    useColorMode,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import T from "../../../../Core/Translations";
import { loadCaptionSettings, saveCaptionSettings } from "../../../../../Lib/Theme/settings";
import { setCaptionSettings } from "../../../../Core/Data/Store/Actions/general";

const THEMES: any = {
    light: { name: 'Light', bgColor: '#F4F2F3', color: 'black', btnVariant: "outline" },
    dark: { name: 'Dark', bgColor: 'black', color: 'white' },
};

const FONTS = [
    "Arial",
    "Verdana",
    "Courier New",
];

export const CaptionSettings = forwardRef(({}: any, ref: any) => {
    
    const [ selectedTheme, setSelectedTheme ] = useState(loadCaptionSettings().theme);
    const [ selectedFont, setSelectedFont ] = useState(FONTS[0]);
    const [ selectedSize, setSelectedSize ] = useState(14);
    const [ isOpen, setIsOpen ] = useState(false);
    
    const dispatch = useDispatch();
    
    const { colorMode } = useColorMode();
    
    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
    }));
    
    useEffect(() => {
        const settings = loadCaptionSettings();
        if (settings.font) setSelectedFont(settings.font);
        if (settings.size) setSelectedSize(settings.size);
        if (settings.theme !== undefined) setSelectedTheme(settings.theme);
    }, []);
    
    useEffect(() => {
        const captionSettings = {
            font: selectedFont,
            size: selectedSize,
            theme: selectedTheme,
        };
        saveCaptionSettings(captionSettings);
        dispatch(setCaptionSettings(captionSettings));
    }, [selectedFont, selectedSize, selectedTheme]);
    
    useEffect(() => {
        setSelectedTheme(colorMode);
    }, [colorMode]);
    
    const increaseSize = (val: number) => {
        if (!val) return;
        
        val += selectedSize;
        if (val < 10) val = 10;
        if (val > 24) val = 24;
        setSelectedSize(val);      
    }
    
    return (
        <Modal size="sm" isCentered isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <ModalOverlay />
			<ModalContent>
				<ModalBody gap="12px" display="flex" flexDir="column">
                    <Flex direction="column" gap="24px">
                        <Heading size="md">{T.get('Theme and Captions')}</Heading>
                        <Flex direction="row" gap="24px">
                            <Box w="full">
                                <Text>{T.get('Font Type')}:</Text>
                                <Select w="full" onChange={e => setSelectedFont(e.target.value)} value={selectedFont}>
                                    {FONTS.map(font => <option>{font}</option>)}
                                </Select>
                            </Box>
                            <Box>
                                <Text>{T.get('Font Size')}:</Text>
                                <Flex gap="8px">
                                    <Button variant="bePrimary" fontSize="small" title={T.get('Decrease font size')} onClick={() => increaseSize(-1)}>A</Button>
                                    <Button variant="bePrimary" fontSize="x-large" title={T.get('Increase font size')} onClick={() => increaseSize(1)}>A</Button>
                                </Flex>
                            </Box>
                        </Flex>
                        <Box>
                            <Text>{T.get('Theme')}:</Text>
                            <Flex gap="8px" w="full">
                                {Object.keys(THEMES).map(themeName => (
                                    <Button
                                        w="full"
                                        variant={THEMES[themeName || ''].btnVariant}
                                        bg={THEMES[themeName || ''].bgColor}
                                        color={THEMES[themeName || ''].color}
                                        _hover={{ bg: THEMES[themeName || ''].bgColor, color: THEMES[themeName || ''].color, borderRadius: 0}}
                                        borderColor={themeName == selectedTheme ? "danger" : "transparent"}
                                        borderWidth="3px"
                                        onClick={() => setSelectedTheme(themeName)}
                                    >{THEMES[themeName].name}</Button>
                                ))}
                            </Flex>
                        </Box>
                        <Box>
                            <Text>{T.get('Captions Preview')}:</Text>
                            <Box p="8px"
                                bg={THEMES[colorMode]?.bgColor}
                                color={THEMES[colorMode]?.color}
                                fontFamily={selectedFont}
                                fontSize={selectedSize + 'px'}
                            >
                                {T.get('A quick white car was coming down the street.')}
                            </Box>
                        </Box>
                    </Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
    );
});

import { BrowserRouter as Router, Routes, Route, useSearchParams } from "react-router-dom";

import Dashboard from "../AppBase/Screens/Dashboard/Web";
import CreateSession from "../AppBase/Screens/CreateSession/Web";
import JoinSession from "../AppBase/Screens/JoinSession/Web";
import SessionJoined from "../AppBase/Screens/SessionJoined/Web";
import SessionListen from "../AppBase/Screens/SessionListen/Web";
import SessionDetails from "../AppBase/Screens/Details/Web";
import AnnotationsWebViewer from "../AppBase/Screens/AnnotaionViewer/Web";
import ShareSessionPage from "../AppBase/Screens/ShareSession/Web";
import ConsentScreen from "../AppBase/Screens/ConsentScreen/Web";
import TeamsSettings from "../AppBase/Screens/Apps/Teams/Settings/Web";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Center, Spinner, useColorMode } from "@chakra-ui/react";
import BonfireAPI from "../AppBase/Core/Data/Http";
import { GET_USER } from "../AppBase/Models/Users/urls";
import UserModel from "../AppBase/Models/Users";
import config from "../AppBase/Config";
import { datadogRum } from "@datadog/browser-rum";
import ActivationRequired from "../AppBase/Screens/SessionJoined/Components/ActivationRequired/Web";
import ActivateUser from "../AppBase/Screens/SessionJoined/Components/ActivateUser/Web";
import TeamsDashboardScreen from "../AppBase/Screens/Apps/Teams/Dashboard";
import ZoomDashboardScreen from "../AppBase/Screens/Apps/Zoom/Dashboard";
import LanguageDetection from "../AppBase/Screens/LanguageDetection/Web";
import ConferenceListener from "../AppBase/Screens/Conference/Web";
import { loadCaptionSettings } from "../Lib/Theme/settings";
import { setCaptionSettings } from "../AppBase/Core/Data/Store/Actions/general";
import { RootState } from "../AppBase/Core/Data/Store/Reducers";

datadogRum.init({
  applicationId: config.datadogRum.applicationId,
  clientToken: config.datadogRum.clientToken,
  site: "datadoghq.com",
  service: "boostlingo-ai-pro",
  env: config.datadogRum.env,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<any>();

  const { setColorMode } = useColorMode(); 
  const queryParams = new URLSearchParams(window.location.search);
  const forceTheme = queryParams.get('forceTheme'); // when embedded as IFRAME in Access portal this overrides any other settings or themes we might have configured
  const theme = forceTheme || queryParams.get('theme');
  
  const dispatch = useDispatch();
  const captionSettings: any = useSelector((state: RootState) => state.general.captionSettings);
  
  useEffect(() => {
    dispatch(setCaptionSettings(loadCaptionSettings()));
  }, []);

  const checkIsLoggedIn = async () => {
    // Temp hack for zoom app, allow without logging in
    if (
      (document.location.href.indexOf("type=") >= 0 ||
        document.location.href.indexOf("/conference") >= 0 ||
        document.location.href.indexOf("session-joined") >= 0 ||
        document.location.href.indexOf("/consent") >= 0 ||
        document.location.href.indexOf("/activate") >= 0 ||
        document.location.href.indexOf("/apps/teams/settings") >= 0 ||
        document.location.href.indexOf("/apps/zoom/dashboard") >= 0
      ) &&
      document.location.href.indexOf("type=tab-capture") < 0
    ) {
      return setIsLoggedIn(true);
    }

    try {
      const me = await BonfireAPI.request(GET_USER, UserModel) as UserModel;

      datadogRum.setUser({
        id: me.id
      });

      setIsLoggedIn(true);
      setUserData(me);
    } catch (error) {
      redirectToLogin();
    }
  };

  const checkIsDashboardEmbedded = () => {
    // if dashboard pages are not embedded in an application we redirect to access
    if (
      window.self === window.top &&
      (window.location.pathname === "/dashboard" ||
        window.location.pathname === "/")
    ) {
      document.location.href = `${config.access.url}/AI-Pro`;
    }
  };

  const redirectToLogin = () => {
    const url = window.location;
    const data = { successRedirect: `${url}` };
    const encodedData = btoa(JSON.stringify(data));

    let loginUrl = `${config.login.url}/login?t=${encodedData}&p=aipro`;
    document.location.href = loginUrl;
  };      

  const handleColorMode = () => {

    const colorModeDefault = window.matchMedia?.("(prefers-color-scheme: dark)").matches ? "dark" : "light";

    let colorMode = colorModeDefault.toLowerCase();
    if (theme) { // Teams passes theme as query string parameter
      if (["dark", "light"].includes(theme.toLowerCase())) colorMode = theme; // make sure we support only recognized themes
    }

    if (captionSettings?.theme && !forceTheme) { // user has already preselected manually a different dark or light theme
      setColorMode(captionSettings.theme);
    } else {
      setColorMode(colorMode); // set color mode, depending on current user preference (OS settings) or passed theme parameter in URL
    }

  }

  useEffect(() => {
    handleColorMode();
    checkIsLoggedIn();
    checkIsDashboardEmbedded();
    setCaptionSettings(loadCaptionSettings());
  }, []);
  
  useEffect(() => {
    handleColorMode();
  }, [captionSettings]);

  if (!isLoggedIn && !document.location.href.includes("annotations")) {
    return (
      <Center
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        h={["full", "full", "calc(100vh)"]}
      >
        <Spinner />
      </Center>
    );
  }

  return (<>
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/conference" element={<ConferenceListener />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/create-session" element={<CreateSession />} />
        <Route path="/language-detection" element={<LanguageDetection />} />
        <Route
          path="/session-details/:sessionId"
          element={<SessionDetails />}
        />
        <Route path="/join-session" element={<JoinSession />} />
        <Route path="/share-session/:sessionId" element={<ShareSessionPage />} />
        <Route path="/session-joined/:sessionId" element={<SessionJoined />} />
        <Route
          path="/annotations/:sessionId"
          element={<AnnotationsWebViewer />}
        />
        <Route path="/session-listen" element={<SessionListen />} />
        <Route path="/consent" element={<ConsentScreen />} />
        <Route path="/apps/teams/settings" element={<TeamsSettings />} />
        <Route path="/apps/teams/dashboard" element={<TeamsDashboardScreen />} />
        <Route path="/apps/zoom/dashboard" element={<ZoomDashboardScreen />} />
        <Route path="/activate" element={<ActivateUser />} />
      </Routes>
    </Router>
    {userData?.activationCode && <ActivationRequired userData={userData} />}
    </>);
};

export default App;

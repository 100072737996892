import {APP_OPENED, SESSION_INFORMATION_OPENED, CAPTIONS_SETTINGS_CHANGED} from '../Constants/general';
import { loadCaptionSettings } from "../../../../../Lib/Theme/settings";

export default function GeneralReducer(
  state = {
    isAppWasOpened: false,
    isSessionInformationOpened: false,
    captionSettings: loadCaptionSettings(),
  },
  action: {type: string; payload: any}
) {
  if (action.type === APP_OPENED) {
    const newState = Object.assign({}, state);
    newState.isAppWasOpened = action.payload;
    return newState;
  } else if (action.type === SESSION_INFORMATION_OPENED) {
    const newState = Object.assign({}, state);
    newState.isSessionInformationOpened = action.payload;
    return newState;
  } else if (action.type === CAPTIONS_SETTINGS_CHANGED) {
    const newState = Object.assign({}, state);
    newState.captionSettings = action.payload;
    return newState;
  }

  return state;
}

import { Box, Progress, Text, Button, Flex } from "@chakra-ui/react";
import { useUserSubscription } from "../../../../../Core/Hooks/useDashboard";
import T from "../../../../../Core/Translations";
import { CLR_SECONDARY_BG } from "../../../../../../Lib/Theme/Light/colors";

const PlatformUsageBox = ({
  session, sessionLiveMinutesUsed, dashboardStyle, onUpgrade
}: {
  session?: any;
  sessionLiveMinutesUsed?: number;
  dashboardStyle?: boolean; // indicates a change in design (to be shown on Zoom dashboard)
  onUpgrade?: () => void;
}) => {
  const { userSubscription } = useUserSubscription();

  const sessionHasEnded = session?.session?.session?.status === "stopped";

  const minutesUsed = sessionLiveMinutesUsed ? sessionLiveMinutesUsed : userSubscription?.minutesUsed || 0;

  const plan = session?.session?.session?.plan;
  const sessionIsFreeTrial = plan ? plan?.name === "freeTrial" : userSubscription?.freeTrial;
  const freeMinutes = userSubscription?.trial?.freeMinutes;

  if (!sessionIsFreeTrial) {
    //return null;
  }
  
  const boxAttrs: any = dashboardStyle ? {
    borderRadius: "10px",
    bgGradient: `linear(to-b, ${CLR_SECONDARY_BG}, #82c5bf)`, // colors from the BoostLingo logo
  } : {
    borderBottomRadius: "4px",
  };
  
  const textAttrs: any = dashboardStyle ? {
    color: "white",
  } : {};

  return (
    <Box
      bg="mutedBlue"
      w="full"
      p="10px"
      pl="23px"
      pr="23px"
      color="white"
      {...boxAttrs}
    >
      <Flex>
        <Text variant="beTextDescriptionFixed" w="full" {...textAttrs}>{T.get('You are on the free trial')}</Text>
        {!!onUpgrade && <Button size="sm" mb="10px" variant="beOrange" onClick={onUpgrade}>Upgrade</Button>}
      </Flex>
      <Progress
        aria-label={T.get("Usage minutes used")}
        borderRadius="4px"
        max={userSubscription?.trial?.freeMinutes}
        bg="white"
        value={minutesUsed}
      />
      <Text textAlign="right" variant="beTextDescriptionFixed" {...textAttrs}>
        {minutesUsed} of {freeMinutes} minutes used
      </Text>
    </Box>
  );
};

export default PlatformUsageBox;

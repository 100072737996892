import { useEffect, useState } from "react";
import {
  Box,
  Center,
  Flex,
  Button,
  Image,
  Heading,
  useColorMode,
} from "@chakra-ui/react";
import T from "../../../../Core/Translations";
import config from "../../../../Config";
import useUser from "../../../../Core/Hooks/useUser";
import { useUserSubscription } from "../../../../Core/Hooks/useDashboard";
import PlatformUsageBox from "../../../SessionJoined/Components/PlatformUsageBox/Web";
import ConsentScreen from "../../../ConsentScreen/Web";

const sendToParent = (command: string, data?: any) => {
  window.parent?.postMessage({
    boostevents: "boostevents",
    command,
    ...data,
  }, '*');
};

function PublicDashboard() {
  
  const [consentScreen, setConsentScreen] = useState(false);
  
  if (consentScreen) {
    return (
      <ConsentScreen />
    );
  }
  
  return (
    <Box
      bgImage="url('/assets/images/green_b_720.png')"
      bgAttachment="fixed"
      bgRepeat="no-repeat"
      bgPosition="100% -25%"
      h="100vh"
    >
      <Center
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        pt={[0, 0, 20]}
      >
        <Flex justify="center" gap="12px" mt="12px" mb="24px">
          <Flex
            maxW={["full", "full", "1000px"]}
            h={["calc(100vh)", "calc(100vh)", "full"]}
            bgColor="white"
            flexDirection="column"
            padding={[8, 8, 10]}
            overflow="auto"
          >
            <Image src="/assets/images/boostlingo-events.jpg" alt="Dashboard" />
            <Heading textAlign="center" fontSize="20px" m="30px 0">{T.get('Captions and AI Translations for your meetings')}</Heading>
            <Flex justify="center" gap="12px" mt="12px" mb="24px">
              <Button onClick={() => setConsentScreen(true)} variant="beOrange" borderRadius="35px" fontSize="30px" p="30px">
                {T.get('Get started!')}
              </Button>
            </Flex>
          </Flex>
          <Box padding={[8, 8, 10]}>
            <Image src="/assets/images/ai-pro-preview.jpg" alt="Dashboard" maxW="700px" />
          </Box>
        </Flex>
      </Center>
      <Center
        bgImage="url('/assets/images/navy_circle.png')"
        bgAttachment="fixed"
        bgRepeat="no-repeat"
        bgPosition="50% 80%"
        bgSize="150px"
        position="fixed"
        bottom="0"
        top="50%"
        left="0"
        right="0"
      >
      </Center>
    </Box>
  );
}

function UserDashboard(props: any) {
  const { user } = props;
  
  const [minutesUsed, setMinutesUsed] = useState();
  
  const { userSubscription, getSubscription } = useUserSubscription();
  
  const startZoomMeeting = () => sendToParent('startMeeting');
  const learnMore = () => sendToParent('openUrl', { url: config.access?.url });
  const aiProDashboard = () => sendToParent('openUrl', { url: config.access?.url });
  
  const onUpgrade = () => sendToParent('openUrl', { url: config.access?.url });
  
  useEffect(() => {
    function onMessage(message: any) {
      switch (message.data.command) {
        case "minutesUsed":
          setMinutesUsed(message.data.minutesUsed);
          break;
        case "meetingEnded":
          getSubscription(); // once a meeting ends we're retrieving up-to-date user usage data
          break;
      }
    }
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, []);
  
  useEffect(() => {
    // If we retrieve a new user subscription from server, clean-up runtime minutesUsed (it will have them up-to-date correct in data which we've just fetched).
    setMinutesUsed(undefined);
  }, [userSubscription]);
  
  return (
    <Center
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mt={[0, 0, 20]}
    >
      <Flex
        maxW={["full", "full", "1000px"]}
        h={["calc(100vh)", "calc(100vh)", "full"]}
        bgColor="white"
        flexDirection="column"
        padding={[8, 8, 10]}
        overflow="auto"
      >
        <Image src={'/assets/images/aipro-logo.jpg'} alt="Dashboard" maxW="400px" />
        <Center><Heading textAlign="center" fontSize="20px">{T.get('Welcome to Boostlingo AI Pro - Captions and AI Translations for your meetings')}</Heading></Center>
        <Box p="40px 0">
          <PlatformUsageBox dashboardStyle={true} onUpgrade={onUpgrade} sessionLiveMinutesUsed={minutesUsed} />
        </Box>
        <Flex justify="center" gap="12px" mt="12px" mb="24px">
          <Box>
            <Image src={'/assets/images/aipro-logo2.jpg'} alt="Dashboard" maxW="100px" />
          </Box>
          <Center>
            <Flex justify="center" gap="12px">
              <Button onClick={startZoomMeeting} variant="beSecondary" borderRadius="35px">{T.get('Start meeting in Zoom')}</Button>
              <Button onClick={learnMore} variant="beSecondary" borderRadius="35px">{T.get('Learn more')}</Button>
              <Button onClick={aiProDashboard} variant="beSecondary" borderRadius="35px">{T.get('Log into AI Pro Dashboard')}</Button>
            </Flex>
          </Center>
        </Flex>
      </Flex>
    </Center>
  );
}

export default function ZoomDashboardScreen() {
  
  const { user, getActiveUser } = useUser();
  
  const { setColorMode } = useColorMode();
  
  useEffect(() => {
    setColorMode('light'); // the dashboard is always in light mode in Zoom
    
    function onMessage(message: any) {
      switch (message.data.command) {
        case "refreshUser": // message is sent from Zoom wrapper parent IFRAME when user accepts consent screen and authorizes successfully (i.e. already logged in and a user account has been created in our database)
          //window.location.reload();
          getActiveUser(); // this will retrieve currently logged in user and on update after that will show Dashboard instead of Welcome screen if user already has logged in
          break;
      }
    }
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, []);
  
  if (!user?.id) { // user is an empty object if it's not loaded
    return <PublicDashboard />; // user not yet logged-in in our app
  }
  return <UserDashboard user={user} />; // logged-in user
}

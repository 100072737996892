// @ts-nocheck
import ModelAbstract from '../ModelAbstract';

export default class SubscriptionsModel extends ModelAbstract {
	active: boolean;
	availableMinutes: boolean;
	planData: any;
	freeTrial: boolean;
	minutesUsed: number;
	trial?: {
		freeMinutes: number;
		maxMinutes: number;
		maxListenLangs: number;
		maxCaptionsLangs: number;
		maxSpeechLangs: number;
	}

	constructor(data: any) {
		super();
		this.active = data.active;
		this.availableMinutes = data.availableMinutes;
		this.planData = data.planData;
		this.freeTrial = data.freeTrial;
		this.minutesUsed = data.minutesUsed;
		this.trial = data.trial;

		this.mapData();
	}

	mapData(): void {

	}

	validate(data: any): string {

		if (data.status == "success") {
			return null;
		}

		if (data.error) {
			return data.error;
		}

		return "Unexpected error";

	}

	static handleError(error: Error): void {
		console.log(error);
	}
}

import { useState, useEffect, useRef } from "react";
import CreateSessionViewModel from "../../Screens/CreateSession/Core/viewModel";
import JoinSessionViewModel from "../../Screens/JoinSession/Core/viewModel";
import SessionStatusModel from "../../Models/SessionStatus";
import { getUserSubscription } from "../Data/Store/Actions/user";
import { getMeetingInstanceOptions } from "../Data/Store/Actions/session";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Data/Store/Reducers";
import SubscriptionsModel from "../../Models/Subscriptions";
import { setPageTitle } from "../../../Lib/Utils/page-title";
import config from "../../Config";

export interface CreateData {
  speakingLanguage: string[];
  dictionaryId?: string | undefined;
  sessionType: string;
  sessionDescription?: string;
  sessionAccessType: string;
  sessionName: string;
  languages: any;
  speechLanguages: any;
  tags?: string[];
  autoClean: boolean;
}

export default function useSessionCreated(
  createState: any,
  navigateSessionJoined: any
) {
  const dispatch = useDispatch();

  let sessionTypeFromParams: null | string;
  let isAccess: boolean | undefined;

  if (process.env.REACT_APP_PLATFORM === "web") {
    const searchParams = new URLSearchParams(window.location.search);

    sessionTypeFromParams = searchParams.get("type");
    isAccess = searchParams.get("isAccess") === "true";
    if (isAccess) {
      document.domain = 'boostlingo.com';
    }
  }

  const [createWithProvider, setCreateWithProvider] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const updatesInterval = useRef<any>(null);

  const subscription = useSelector(
    (state: RootState) => state.user.subscription as SubscriptionsModel
  );

  const meetingInstanceOptions = useSelector(
    (state: RootState) => state.session.meetingInstanceOptions as any
  );

  const startCheckingForUpdates = () => {
    const check = async () => {
      try {
        getSubscription();
        dispatch(
          getMeetingInstanceOptions(
            createState.options.type,
            createState.options.clientId,
            createState.options.meetingKey
          )
        );

        const data = (await JoinSessionViewModel.processJoinProvider(
          createState.options.type,
          {
            meetingId: createState.options.meetingId,
          }
        )) as SessionStatusModel;

        if (data.raw) {
          clearInterval(updatesInterval.current);
          navigateSessionJoined(data, createState.options.type);
        }
      } catch (error) {
        console.log("Error on updates interval", error);
      }
    };

    updatesInterval.current = setInterval(check, 3000);
    check();
  };

  const getSubscription = async () => {
    dispatch(getUserSubscription());
  };

  const createSessionWithLocal = async (
    createData: CreateData,
    autoStartDisabled = true,
    isRedirect?: boolean
  ) => {
    try {
      setIsLoading(true);

      const data =
        await CreateSessionViewModel.processCreateSessionWithProvider(
          createData.sessionType,
          { ...createData, autoStartDisabled }
        );

      if (isAccess) {
        // for iframe for creating a session from access
        window.parent.postMessage(
          { eventName: "sessionCreated", data },
          "*"
        );
        if (isRedirect && data && 'raw' in data) {
          window.open(
            `${config.webapp.url}/session-joined/${data.raw.session_id}`,
          );
        }
        return;
      }

      navigateSessionJoined(data, "local");
    } catch (error: any) {
      setError(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const createSessionWithProvider = async (
    createData: CreateData,
    autoStartDisabled = false
) => {
    try {
      setIsLoading(true);

      var options = { ...createState?.options };

      const data =
        await CreateSessionViewModel.processCreateSessionWithProvider(
          createState?.options.type,
          { ...options, ...createData, autoStartDisabled }
        );
      navigateSessionJoined(data, createState?.options.type);
    } catch (error: any) {
      setError(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSubscription();

    if (createState?.options.type && !createState?.copyFrom) {
      startCheckingForUpdates();
      setCreateWithProvider(true);
    }

    return () => {
      if (updatesInterval.current) {
        clearInterval(updatesInterval.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionTypeFromParams == "quick-local") {
      createSessionWithLocal({
        sessionType: "local",
        sessionAccessType: "public",
        sessionName: "Quick Session",
        speakingLanguage: ["en-US"],
        speechLanguages: [],
        languages: [],
        autoClean: false,
      }, false);
      
      setPageTitle("Quick Local");
    } else if (createState?.quickCreate) {
      createSessionWithProvider({
        sessionType: "local",
        sessionAccessType: "public",
        sessionName: "Quick Session",
        speakingLanguage: ["en-US"],
        languages: [],
        speechLanguages: [],
        autoClean: false,
      });
    }
  }, []);

  return {
    createSessionWithProvider,
    createSessionWithLocal,
    createWithProvider,
    isLoading,
    error,
    setIsLoading,
    subscriptionActive: subscription?.availableMinutes,
    meetingInstanceOptions,
  };
}

import React, { useState, useEffect, useRef } from "react";
import { useToast  } from "@chakra-ui/react";
import KeywordsModel from "../../Models/Keywords";
import { GET_DICTIONARIES } from "../../Models/Keywords/urls";
import BonfireAPI from "../Data/Http";
import useUser from "./useUser";
import T from "../Translations";
import SubscriptionsModel from "../../Models/Subscriptions";
import { GET_LANGUAGES, GET_SPEAKING_LANGUAGES, GET_SPEECH_LANGUAGES } from "../../Models/Languages/urls";
import LanguagesModel from "../../Models/Languages";

export default function useSessionInfoForm(sessionId?: string, subscription?: SubscriptionsModel) {
  const [speakingLanguage, setSpeakingLanguage] = useState<
    { value: string; label: string }[]
  >([{ value: "en-US", label: "English" }]);
  const [dictionaryId, setDictionaryId] = useState<string>();
  const [sessionType, setSessionType] = useState("local");
  const [sessionAccessType, setSessionAccessType] = useState("public");
  const [sessionName, setSessionName] = useState("");
  const [sessionDescription, setSessionDescription] = useState("");
  const [dictionaries, setDictionaries] = useState<KeywordsModel[]>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [captionLanguages, setCaptionLanguages] = useState<
    { value: string; label: string }[]
  >([]);
  const [translateMode, setTranslateMode] = useState(false);
  const [autoClean, setAutoClean] = useState<boolean>(false);
  const [consumePerMinute, setconsumePerMinute] = useState<number>(1);
  const [speechLanguages, setSpeechLanguages] = useState<
    { value: string; label: string }[]
  >([]);
  const [speechToSpeechMode, setSpeechToSpeechMode] = useState<boolean>(false);
  const [moveSessionDataToQa, setMoveSessionDataToQa] = useState<boolean>(false);

  const { user } = useUser();
  const selectedOrganizationId = useRef<string>("");
  
  const toast = useToast();
  
  const { maxCaptionsLangs } = subscription?.trial || {};

  useEffect(() => {
    try {
      getDictionaries();

      if (sessionId) {
        getSessionDetails();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsPageLoading(false);
    }
  }, [sessionId]);

  // Hook to update data when the user changes the organization
  useEffect(() => {
    if (selectedOrganizationId.current && selectedOrganizationId.current != user.selectedOrganizationId) {
      getDictionaries();
    }
    selectedOrganizationId.current = user.selectedOrganizationId;
  }, [user.selectedOrganizationId]);

  useEffect(() => {
    setconsumePerMinute(calculateConsumePerMinute());
  }, [speakingLanguage, captionLanguages, speechLanguages]);

  useEffect(() => {
    hasOrganizationEnabledDataMove();
  }, [selectedOrganizationId.current]);
  
  const langCodesToObjects = (languages: LanguagesModel[], langArr: string[]) => {
    if (!langArr) return [];
    return langArr.map((langCode: string) => {
      const lang = languages?.find(lang => lang.code == langCode)
      if (lang) return { value: lang.code, label: lang.name };
      return { value: langCode, label: langCode };
    });
  };

  const getSessionDetails = async () => {
    try {
      const data = await BonfireAPI.get(`/sessions/session/${sessionId}`, null);
      if (data.error) return;
      
      // fetch all types of languages in parallel
      const [speakingLanguages, languages, speechLanguages ] = await Promise.all([
        BonfireAPI.requestArray(GET_SPEAKING_LANGUAGES, LanguagesModel).catch(exc => Promise.resolve()), // ignore errors (languages will be displayed as codes instead of name)
        BonfireAPI.requestArray(GET_LANGUAGES, LanguagesModel).catch(exc => Promise.resolve()),
        BonfireAPI.requestArray(GET_SPEECH_LANGUAGES, LanguagesModel).catch(exc => Promise.resolve()),
      ]);
      
      setSessionName(data.name);
      setDictionaryId(data.dictionaryIds);
      setSessionAccessType(data.type);
      setSpeakingLanguage(langCodesToObjects(speakingLanguages as LanguagesModel[], [data.speakingLanguage]));
      setCaptionLanguages(langCodesToObjects(languages as LanguagesModel[], data.languages));
      setSpeechLanguages(langCodesToObjects(speechLanguages as LanguagesModel[], data.speechLanguages));
      
      if (data.languages?.length || data.speechLanguages?.length) {
        setTranslateMode(true);
      }
      if (data.speechLanguages?.length) {
        setSpeechToSpeechMode(true);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const hasOrganizationEnabledDataMove = async () => {
    const data = await BonfireAPI.get(`/users/me/selectedOrganization/${selectedOrganizationId.current}`, null);

    if (data && data.moveSessionDataToQa !== undefined) {
      setMoveSessionDataToQa(data.moveSessionDataToQa);
    }
  }

  const getDictionaries = async () => {
    try {
      const dictionariesList = await BonfireAPI.requestArray(
        GET_DICTIONARIES,
        KeywordsModel
      );
      setDictionaries(dictionariesList as KeywordsModel[]);
    } catch (error) {
      throw error;
    }
  };

  const onChangeSessionType = (value: any) => {
    setSessionType(value);
  };

  const onChangeSessionAccessType = (value: any) => {
    setSessionAccessType(value);
  };

  const onChangeSessionName = (value: string) => {
    setSessionName(value);
  };

  const onChangeSessionDescription = (value: string) => {
    setSessionDescription(value);
  };

  const onChangeCaptionLanguages = (
    languages: { value: string; label: string }[]
  ) => {
    setCaptionLanguages(languages);
    const deletedElementsForSpeech = captionLanguages.filter((item: any) => !languages.includes(item));
    if (deletedElementsForSpeech.length > 0) {
      setSpeechLanguages((prevState) => prevState.filter((item: any) => !deletedElementsForSpeech.some(caption => caption.label === item.label)));
    }
  };

  const onChangeSpeechLanguages = (
    languages: { value: string; label: string }[]
  ) => {
    const addedElements = languages.filter((item: any) => !speechLanguages.includes(item) && !captionLanguages.some(caption => caption.label === item.label));
    if (addedElements.length > 0) {
      if (maxCaptionsLangs && captionLanguages.length >= maxCaptionsLangs) {
        toast({
          title: T.get("Can't add a new speech language"),
          description: T.get("We need to add the new speech language to captions languages as well, but due to max limit reached there we can't have more speech languages."),
          status: "error",
          duration: 10000,
          position: "top",
          isClosable: true,
        });
        setSpeechLanguages([...speechLanguages]);
        return;
      }
      setCaptionLanguages((prevState) => [...prevState, ...addedElements]);
    }
    setSpeechLanguages(languages);
  };

  const onChangeDictionary = (value: any) => {
    setDictionaryId(value);
  };

  const onChangeSpeakingLanguage = (
    languages: { value: string; label: string }[]
  ) => {
    setSpeakingLanguage(languages);
    if (translateMode) {
      const addedElements = languages.filter((item: any) => !speakingLanguage.includes(item) && !captionLanguages.some(caption => caption.label === item.label));
      if (addedElements.length > 0) {
        setCaptionLanguages((prevState) => [...prevState, ...addedElements]);
      }

      const deletedElements = speakingLanguage.filter((item: any) => !languages.includes(item));
      if (deletedElements.length > 0) {
        setCaptionLanguages((prevState) => prevState.filter((item: any) => !deletedElements.some(caption => caption.label === item.label)));
        setSpeechLanguages((prevState) => prevState.filter((item: any) => !deletedElements.some(caption => caption.label === item.label)));
      }
    };
  }

  const onToggleTranslateMode = () => {
    setTranslateMode(!translateMode);
    speechToSpeechMode === true && setSpeechToSpeechMode(!translateMode)
    translateMode ? setCaptionLanguages([]) : setCaptionLanguages(speakingLanguage);
  };

  const onToggleSaveToProfileMode = () => {
    setAutoClean(!autoClean);
  };

  const onToggleSpeechToSpeechMode = () => {
    setSpeechToSpeechMode(!speechToSpeechMode);
    translateMode === false && setTranslateMode(!speechToSpeechMode)
    !translateMode && speechToSpeechMode ? setCaptionLanguages([]) : setCaptionLanguages(speakingLanguage);
    setSpeechLanguages([])
    //speechToSpeechMode ? setSpeechLanguages([]) : setSpeechLanguages(speakingLanguage);
  }
  const normalizeLanguage = (language: string) => {
    return language.split('-')[0].toLowerCase(); // Normalize by splitting and using the base language
  };

  const calculateConsumePerMinute = () => {
    // Commented code is the logic suggested by Tadas.
    // length = speakingLanguage.length;

    // if(!translateMode) return length;

    // for (let i = 0; i < speakingLanguage.length; i++) {
    //   for (let j = 0; j < captionLanguages.length; j++) {
    //     if (speakingLanguage[i] !== captionLanguages[j]) {
    //       length++;

    //     }
    //   }
    // }
    // return length;

    // The logic below is the one suggested by Nikolai
    // let speakingLanguagesLength = speakingLanguage.length;
    // let captionLanguagesLength = captionLanguages.length;
    // let speechLanguagesLength = speechLanguages.length;

    // if (speakingLanguage.length === 1 && captionLanguages.length === 1) {
    //   return 1 + speechLanguagesLength;
    // }

    // The logic below is the one suggested similarly implemented in the backend

    if (speakingLanguage.length === 1 && captionLanguages.length > 0 &&
      normalizeLanguage(speakingLanguage[0].value) === normalizeLanguage(captionLanguages.find((item: any) => normalizeLanguage(item.value) === normalizeLanguage(speakingLanguage[0].value))?.value || '')) {
      return captionLanguages.length + speechLanguages.length
    }

    return speechLanguages.length + captionLanguages.length + speakingLanguage.length;
  }

  return {
    speakingLanguage,
    dictionaryId,
    sessionType,
    sessionAccessType,
    sessionName,
    sessionDescription,
    dictionaries,
    isPageLoading,
    captionLanguages,
    translateMode,
    autoClean,
    consumePerMinute,
    speechLanguages,
    speechToSpeechMode,
    onChangeSpeechLanguages,
    onChangeSessionType,
    onChangeSessionAccessType,
    onChangeSessionName,
    onChangeSessionDescription,
    onChangeCaptionLanguages,
    onChangeDictionary,
    onChangeSpeakingLanguage,
    onToggleTranslateMode,
    onToggleSaveToProfileMode,
    onToggleSpeechToSpeechMode,
    moveSessionDataToQa
  };
}

// @ts-nocheck
import {
  Box,
  Text,
  Flex,
  Select,
  IconButton,
  Tooltip,
  Image,
  Divider,
  Button,
  Center,
} from "@chakra-ui/react";
import T from "../../../Core/Translations";
import {
  useLocation,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import SessionJoinedHelpers from "../Helpers/Web";
import useSessionJoined from "../../../Core/Hooks/useSessionJoined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStop,
  faPause,
  faPlay,
  faArrowLeft,
  faPencil,
  faFont,
} from "@fortawesome/free-solid-svg-icons";
import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/alert";
import Messages from "../Components/Messages/Web";
import { useToast } from "@chakra-ui/react";
import useBrowserExtension from "../../../Core/Hooks/useBrowserExtension";
import PlatformUsageBox from "../Components/PlatformUsageBox/Web";
import SessionHasEndedState from "../Components/SessionHasEndedState/Web";
import { useState, useEffect, useRef } from "react";
import ShareSession from "../Components/ShareSession/Web";
import SessionInformation from "../Components/SessionInformation/Web";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../Core/Data/Store/Actions/user";
import UserModel from "../../../Models/Users";
import SessionLoadState from "../Components/SessionLoadState/Web";
import StatusBar from "../Components/StatusBar/Web";
import useUser from "../../../Core/Hooks/useUser";
import UserAvatar from "../../../Components/Avatar/Web";
import TextToSpeechBox from "../Components/TextToSpeechBox/Web";
import MicrophoneInfo from "../Components/MicrophoneInfo/Web";
import { toggleSessionInformationDialog } from "../../../Core/Data/Store/Actions/general";
import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../Lib/Theme/Light/colors";
import { useColorModeValue } from "@chakra-ui/react";
import { CaptionSettings } from "../Components/CaptionSettings";

const SessionJoined = () => {
  const dispatch = useDispatch();
  const captionSettingsModal = useRef();

  const { state } = useLocation();
  const { sessionId } = useParams();
  const [annotating, setAnnotating] = useState(false);
  const navigate = useNavigate();

  const onTextToSpeechPlay = useRef<(bytes: any) => void>(null);

  const { user } = useUser();
  const [searchParams] = useSearchParams();

  const userIsNotGuest = user instanceof UserModel;

  const isTouchDevice = "ontouchstart" in document.documentElement;

  const { error, inBrowserExtension, captureActive, sendToChomeExtension } =
    useBrowserExtension();


  const iosSilenceToastShow = useRef("hidden");

  const goBack = () => {
    subscription.current?.disconnect();

    if (state?.isLinkedToEvent) {   
      // Sometimes there is no history, so the page is just stuck. Disabling for now. 
      // window.history.go(-1);                   
      // return;
    }

    if (state?.initialUrl && provider) {
      return window.location.href = state.initialUrl;      
    } else {
      if (isExternalApp()) {
        return window.parent.postMessage(
            { command: "refresh" },
            "*"
        );
      }
    }

    if (inBrowserExtension) {
      navigate("/dashboard");
    }
  };

  const onunauthorized = () => {
    document.location.href = "/dashboard";
  };

  const onNotificationReceived = (data: any) => {
    showToast(data.title, data.message, data.type || "warning");
  };

  const toast = useToast();

  const onTextToSpeechCallback = (bytes: any) => {
    onTextToSpeechPlay.current?.(bytes);
  }

  const {
    stopSession,
    startSession,
    pauseSession,
    languageChanged,
    languages,
    uniqueLanguages,
    isSpeechToSpeechModeEnabled,
    participantsColors,
    loadingMessages,
    currentLanguage,
    temporarySentence,
    finalSentences,
    sessionState,
    subscription,
    isLoading,
    sessionReady,
    provider,
    sessionLiveMinutesUsed,
    statusLoading,
    statusChangeReason,
    languageRecognized,
    toggleTTS,
    showSilenceWarning,
    usersLimitReached,
  } = useSessionJoined(
    sessionId,
    SessionJoinedHelpers,
    window.Boost,
    state || {},
    goBack,
    onunauthorized,
    onNotificationReceived,
    inBrowserExtension,
    onTextToSpeechCallback,
  );

  useEffect(() => {
    if (!window.parent || window.parent === window) return; // not embedded as an IFRAME (no parent)
    // In case this IFRAME is embedded into a Zoom meeting we post to the parent an update of the sessionLiveMinutesUsed (parent is bonfire-api zoom wrapper that will forward it to the wrapper running inside the main Zoom window from where it will be forwarded to the bonfire-app instance showing Zoom/Dashboard)
    window.parent.postMessage({
      boostevents: "boostevents",
      command: 'minutesUsed',
      minutesUsed: sessionLiveMinutesUsed,
      sessionId
  }, '*');
  }, [sessionLiveMinutesUsed]);

  useEffect(() => {
    if (!inBrowserExtension) return;

    if (captureActive) {
      startSession();
    } else {
      pauseSession();
    }
  }, [captureActive]);

  useEffect(() => {

    if (!inBrowserExtension) return;
    if (!sessionState) return;

    if (!sessionIsActive() && captureActive) {
      sendToChomeExtension({ type: "ui-stop-all" });
    }

  }, [sessionState]);

  const toggleTextToSpeech = (isEnabled: boolean) => {

    toggleTTS(isEnabled);

    const userAgent = window.navigator.userAgent;
    const isIOSUserAgent = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

    if (!isIOSUserAgent) {
      return;
    }

    const status = sessionState?.session?.session?.status;
    if (status === 'started') {
      if (iosSilenceToastShow.current === "hidden") {
        iosSilenceToastShow.current = 1;
      } else if (parseInt(iosSilenceToastShow.current) > 0) {
        iosSilenceToastShow.current += 1;
        if (iosSilenceToastShow.current === 3) {
          showToast("No sound?", "Please check if your device is not in silent mode.", "success");
        }
      }
    }
  }

  const sessionIsActive = () => {
    const status = sessionState?.session?.session?.status;
    return status == "initialized" || status == "started";
  }

  const isExternalApp = () => {
    const eventType = sessionState?.session?.session.eventType;
    return eventType === "zoom" || eventType === "teams";
  }

  const showMicInfo = () => {    

    if (isExternalApp() || inBrowserExtension) {
      return false;
    }
  
    return !finalSentences.length && !temporarySentence
      && sessionState?.session?.session?.status != "stopped" // if session is stopped, then no microphone warning
      && sessionState?.session?.isOwner; // microphone info warning is available only for session owners
  };

  const showToast = (
    title: string,
    description: string,
    status = "warning",
  ) => {
    toast({
      title: T.get(title),
      description: T.get(description),
      status,
      duration: 4000,
      position: "top",
      isClosable: true,
    });
  };

  const renderSessionId =
    sessionState?.session?.session?.shortSessionId ||
    sessionState?.session?.session?.sessionId;

  const sessionStatus = sessionState?.session?.session.status;

  const changeSessionStatus = () => {
    if (sessionStatus === "paused" || sessionStatus === "waiting") {
      if (inBrowserExtension) {
        // if we're inside Chrome Extension, then we request the Chrome Extension to start tab capture first.
        // It can fail or can be denied (because there's already a tab capture on another tab).
        // That's why we do not automatically attempt to start the session here.
        // Session is started when captureActive becomes true (this is a change sent from the extension)
        sendToChomeExtension({ type: "ui-start-tab-capture" });
        sendToChomeExtension({ type: "ui-start-mic-capture" });
      } else {
        // not a browser extension - continue normally
        startSession();
      }
    } else {
      pauseSession();
      if (inBrowserExtension) {
        sendToChomeExtension({ type: "ui-stop-all" });
      }
    }
  };

  const enableAudioContext = () => {
    const audioContext = new AudioContext();

    const oscillator = audioContext.createOscillator();
    oscillator.frequency.value = 25000;

    oscillator.connect(audioContext.destination);
    oscillator.start();

    setTimeout(() => {
      oscillator.stop();
    }, 500);

    enableTTS();
  };

  const amountOfmessages = temporarySentence ? 1 : finalSentences.length;

  const renderDebugInfo = () => {
    const debug = searchParams.get("debug");
    if (!debug) {
      return;
    }

    const tts = searchParams.get("tts");

    return (
      <div style={{ padding: 5, display: "flex", flexDirection: "row" }}>
        {tts && (
          <div>
            <button
              style={{
                borderWidth: 2,
                borderColor: "#ccc",
                paddingLeft: 5,
                paddingRight: 5,
              }}
              onClick={enableAudioContext}
            >
              A
            </button>
          </div>
        )}
        <div>
          <strong>{languageRecognized.toUpperCase()}</strong>
        </div>
      </div>
    );
  };

  const isTeams = () => {
    return sessionState?.session?.session.eventType === "teams"
  }

  const renderTopButtons = (joinedSession) => {
    if (sessionStatus === "stopped") {
      if (provider && provider != "local") {
        return (
          <Flex gap="5px">
            <Tooltip label={T.get("Back")}>
              <IconButton
                variant="bePrimary"
                size="sm"
                borderRadius="50%"
                onClick={goBack}
                icon={<FontAwesomeIcon icon={faArrowLeft} size={"sm"} />}
              />
            </Tooltip>
          </Flex>
        );
      }
      return null;
    }
    
    // if users limit is reached, we're showing only the avatar and no control buttons

    return (
      <Flex gap="5px" alignItems="center" flexWrap={'wrap'} flex={[1,'initial','initial']} rowGap={'10px'}>
        {joinedSession && <>
          {sessionState?.session?.isOwner && (
            <>
              <Tooltip
                isDisabled={isTouchDevice}
                label={
                  sessionStatus === "paused" || sessionStatus === "waiting"
                    ? "Start"
                    : "Pause"
                }
              >
                <IconButton
                  variant="bePrimary"
                  aria-label={sessionStatus === "paused" || sessionStatus === "waiting" ? "Start" : "Pause"}
                  isDisabled={
                    sessionStatus === "stopped" ||
                    sessionStatus === "initialized" ||
                    statusLoading
                  }
                  size="sm"
                  borderRadius="50%"
                  onClick={changeSessionStatus}
                  icon={
                    <FontAwesomeIcon
                      icon={
                        sessionStatus === "paused" || sessionStatus === "waiting"
                          ? faPlay
                          : faPause
                      }
                      size={"sm"}
                    />
                  }
                />
              </Tooltip>
              <Tooltip
                label="Stop"
                isDisabled={isTouchDevice}
              >
                <IconButton
                  variant="beSecondary"
                  size="sm"
                  aria-label="Stop"
                  id={"stopButton"}
                  borderRadius="50%"
                  isDisabled={
                    sessionStatus === "stopped" ||
                    sessionStatus === "initialized" ||
                    statusLoading
                  }
                  onClick={stopSession}
                  icon={<FontAwesomeIcon icon={faStop} size={"sm"} />}
                />
              </Tooltip>
            </>
          )}
          {userIsNotGuest && !isTeams() && sessionState?.session?.isOwner && (
            <Tooltip label={annotating ? "Stop annotating" : "Start annotating"}>
              <IconButton
                variant="beOutline"
                aria-label={annotating ? "Stop annotating" : "Start annotating"}
                onClick={() => setAnnotating(!annotating)}
                size="sm"
                bg={annotating ? "mutedBlue" : "white"}
                borderRadius="50%"
                isDisabled={sessionStatus === "stopped"}
                icon={<FontAwesomeIcon icon={faPencil} size={"sm"} />}
              />
            </Tooltip>
          )}
          <Tooltip label="Set captions style">
              <IconButton
                variant="beOutline"
                aria-label="Set captions style"
                onClick={() => captionSettingsModal.current?.open()}
                size="sm"
                bg="white"
                borderRadius="50%"
                icon={<FontAwesomeIcon icon={faFont} size={"sm"} />}
              />
            </Tooltip>
          <ShareSession sessionId={sessionState?.session?.session?.sessionId} />
          {sessionState?.session?.isOwner && (
            <SessionInformation
              initialLanguages={languages
                .filter((lang) => lang.isTranslationLanguage || (!lang.isSpeakingLanguage && lang.key !== "floor"))
                .map((lang) => ({ value: lang.key, label: lang.name }))}
              currentLanguage={currentLanguage}
              languages={languages}
              languageChanged={languageChanged} // this is unused?
              sessionData={sessionState?.session}
            />
          )}
          {
            isSpeechToSpeechModeEnabled && (

              <TextToSpeechBox
                onRegister={(onTextToSpeechPlayFunc) => {
                  onTextToSpeechPlay.current = onTextToSpeechPlayFunc
                }}
                onToggle={toggleTextToSpeech}
              />
            )
          }
          {renderDebugInfo()}
          <Select
            aria-label="Select language"
            fontWeight="600"
            size="sm"
            minWidth={'120px'}
            bg="white"
            flex={1}
            borderWidth="2px"
            color="primaryBlue"
            borderColor="primaryBlue"
            borderRadius="full"
            value={currentLanguage}
            _hover={{ opacity: 1 }}
            onChange={(event) => {
              languageChanged(event.target.value);
            }}
          >
            {uniqueLanguages.map((lang) => (
              <option key={lang.key} value={lang.key}>
                {lang.name}
              </option>
            ))}
          </Select>
        </>}
        <Divider
          display={{ base: "none", lg: "block" }}
          orientation="vertical"
          ml="5px"
          mr="5px"
          height="40px"
        />
        {userIsNotGuest && <Box display={{ base: "none", lg: "block" }}>
          <UserAvatar readOnly={true} disableOptionalItems={true} />
        </Box>}
      </Flex>
    );
  };
  
  const maxTitleLen = sessionState?.session?.organizationLogo ? 25 : 37;
  
  // Until we receive the subscription confirmation through the WebSocket, we do not display UI messages and controls,
  // because if the users limit has been reached we need not to show this info.
  // TranscriptionSubscriber (websocket) gets initialized after we fetch session messages, so messages could be displayed for a while.
  // To make sure this does not happen, we need to make sure the user has successfully joined in the session with the websocket.
  const joinedSession = sessionReady && !usersLimitReached;

  return (
    <Flex
      display="flex"
      flexDirection="column"
      overflow="auto"
      mt={[0, 0, "40px"]}
      mr={{ base: "none", md: "auto" }}
      ml={{ base: "none", md: "auto" }}
      w={["full", "full", "800px"]}
      bgColor={useColorModeValue(CLR_PANEL_BG, CLR_PANEL_BG_DARK)}
      borderRadius={{ base: "none", md: 8 }}
      h={["100svh", "100svh", "calc(100vh - 80px)"]}
      borderWidth={[0, 0, 1]}
      position="relative"
    >
      {isLoading ? (
        <SessionLoadState sessionReady={sessionReady} />
      ) : (
        <>
          {!!error && <div style={{ color: "red" }}>{error}</div>}
          {sessionState?.session?.isOwner && (
            <PlatformUsageBox
              session={sessionState}
              sessionLiveMinutesUsed={sessionLiveMinutesUsed}
            />
          )}
          <Flex
            p="12px"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap="12px"
          >
            <Flex
              gap="12px"
              alignItems="center"
              w={{ base: "full", lg: "fit-content" }}
              justifyContent="space-between"
            >
              <Flex alignItems="center" gap="12px">
                {sessionState?.session?.organizationLogo && (
                  <Image
                    height="75px"
                    width="75px"
                    src={sessionState.session.organizationLogo}
                    borderRadius="4px"
                  />
                )}
                <Box>
                  <Tooltip label={sessionState.session.session.name.length > maxTitleLen ? sessionState.session.session.name : ''}>
                    <Text variant="beTextHeading" fontSize="16px" fontWeight="600">
                      {sessionState.session.session.name.length < maxTitleLen ? sessionState.session.session.name : sessionState.session.session.name.slice(0, maxTitleLen) + '...'}
                    </Text>
                  </Tooltip>
                  <Text
                    variant="beTextDescription"
                    fontSize="12px"
                    fontWeight="600"
                  >
                    {renderSessionId}
                  </Text>
                </Box>
              </Flex>
              <Flex gap="5px" display={{ base: "flex", lg: "none" }}>
                <Divider
                  orientation="vertical"
                  ml="5px"
                  mr="5px"
                  height="40px"
                />
                {userIsNotGuest && <UserAvatar readOnly={true} disableOptionalItems={true} />}
              </Flex>
            </Flex>
            {renderTopButtons(joinedSession)}
          </Flex>
          {sessionState.session.session.description && <Box ml={5} mr={5}>{sessionState.session.session.description}</Box>}
          {usersLimitReached && (
            <Box mt="12px">
              <Alert status="error" variant="subtle" p="10px" pl="23px" pr="23px">
                <AlertIcon />
                <AlertTitle fontWeight="bold">
                  {T.get("Users limit has been reached. Can't join session until a free seat becomes available.")}
                </AlertTitle>
              </Alert>
            </Box>
          )}
          {joinedSession && (<StatusBar
            session={sessionState?.session}
            statusChangeReason={statusChangeReason}
          />)}
          {joinedSession && showMicInfo() && <Box p={5} backgroundColor={useColorModeValue("#fafafa99", "#fafafa22")} borderRadius={9} m={[0, 5, 10]} position="relative">
            <Center>
              <Text fontSize="sm" fontWeight={"600"}>This session will transcribe audio from your microphone</Text>
            </Center>
            <Center m={3}>
              <Box maxWidth="500px" width={"100%"}>
                <MicrophoneInfo showSelect={false} microphoneSettingsClicked={() => {
                  dispatch(toggleSessionInformationDialog(true));
                }} />
              </Box>
            </Center>
          </Box>}
          {sessionState?.session?.session?.status === "stopped" ? (
            <SessionHasEndedState
              provider={provider}
              sessionId={sessionId}
              userIsNotGuest={userIsNotGuest}
              sessionType={sessionState?.session?.session?.eventType}
            />
          ) : amountOfmessages === 0 &&
            sessionStatus === "started" &&
            sessionState?.session?.isOwner ? (
            <Text textAlign="center" mt="23px" variant="beTextDescription">
              Session is ready, go ahead and speak
            </Text>
          ) : (
            sessionReady && <Messages
              loadingMessages={loadingMessages}
              finalSentences={finalSentences}
              languages={languages}
              temporarySentence={temporarySentence}
              participantsColors={participantsColors}
              hideAnnotations={!annotating || !userIsNotGuest}
              currentLanguage={currentLanguage}
              isTeams={isTeams()}
            />
          )}
          <CaptionSettings ref={captionSettingsModal} />
        </>
      )}
    </Flex>
  );
};

export default SessionJoined;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Data/Store/Reducers";
import { getActiveSessions } from "../Data/Store/Actions/dashboard";
import { getUser, getUserSubscription } from "../Data/Store/Actions/user";
import UserModel from "../../Models/Users";
import SubscriptionsModel from "../../Models/Subscriptions";

export function useUserSubscription() {
  const userSubscription = useSelector(
    (state: RootState) => state.user.subscription as SubscriptionsModel
  );
  const dispatch = useDispatch();

  const getSubscription = async () => {
    dispatch(getUserSubscription());
  };
  useEffect(() => {
    getSubscription();
  }, []);

  return {
    userSubscription,
    getSubscription,
  };
}

export default function useDashboard() {
  const dispatch = useDispatch();
  const sessions = useSelector(
    (state: RootState) => state.dashboard.activeSessions as any
  );
  const user = useSelector(
    (state: RootState) => state.user.activeUser as UserModel
  );

  const { userSubscription } = useUserSubscription();

  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (sessions) {
      setIsRefreshing(false);
      setIsLoading(false);
    }
  }, [sessions]);

  useEffect(() => {
    getUserSessions();
    getActiveUser();
  }, []);

  const getUserSessions = async () => {
    dispatch(getActiveSessions());
  };

  const getActiveUser = async () => {
    dispatch(getUser());
  };

  return {
    sessions,
    user,
    isLoading,
    isRefreshing,
    getUserSessions,
    subscriptionActive: userSubscription?.availableMinutes,
  };
}
